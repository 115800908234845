@import '@popsure/dirty-swan/dist/grid';

.container {
  max-width: 592px;
}

.animatedContainer {
  animation-name: appear-in;
  animation-timing-function: ease;
  animation-delay: 0.3s;
  animation-duration: 0.6s;
  animation-fill-mode: both;

  @keyframes appear-in {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.cardContainer {
  div + div {
    margin-top: 16px;
  }
}
