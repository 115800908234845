@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 64px;
  height: 64px;
}

.checkout-info-container {
  display: flex;
  flex-wrap: wrap;

  margin-left: -16px;

  & > div {
    flex: 1;
    max-width: calc(50% - 16px);

    @include p-size-mobile {
      flex-basis: auto;
    }

    @media (max-width: 780px) {
      min-width: 100%;
      max-width: 100%;
    }
  }
}

.button-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include p-size-mobile {
    margin-top: 16px !important;
  }
}

.button {
  @include p-size-mobile {
    width: 100%;
  }
}

.buttonDocsContainer {
  @include p-size-tablet {
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
    background-color: $ds-white;
    padding: 24px;
    border-radius: 8px;
  }
}
