@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.icon {
  width: 80px;
  height: 80px;
}

.columnItem {
    flex: 1;
    max-width: calc(50% - 16px);

    @include p-size-mobile {
      flex-basis: auto;
    }

    @media (max-width: 780px) {
      min-width: 100%;
      max-width: 100%;
    }
}

.buttonContainer {
  @include p-size-mobile {
    margin-top: 16px !important;
  }
}

.buttonDocsContainer {
  @media(min-width: 700px) {
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
    background-color: $ds-white;
    padding: 24px;
    border-radius: 8px;
  }
}
