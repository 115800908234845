@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  background: white;
  box-shadow: 0px 2px 20px rgba(97, 96, 162, 0.07);
  border-radius: 8px;

  padding: 24px;
}

.inner-container {
  background: transparent;
}

.payment-info-container {
  display: flex;
  justify-content: space-between;
}

.radio-label {
  padding-right: 16px;
  width: 100%;
}

.radio-label::before {
  display: none !important;
}

.payment-select-container {
  border-radius: 8px;
  border: 1px solid $ds-grey-200;
  background-color: $ds-grey-200;
}

.payment-info-input {
  border-radius: 8px;
  border: 1px solid $ds-grey-400;
  height: 48px;
  padding: 0 16px;
}

.existing-card-dropdown {
  display: flex;
  align-items: center;

  padding: 0 16px;
  height: 48px;

  background-color: $ds-grey-200;
  color: $ds-grey-200;

  cursor: pointer;
}

.existing-card-container {
  display: flex;
  align-items: center;

  padding: 0 16px;
  height: 48px;

  background-color: white;

  cursor: pointer;
}

.existing-card-container:hover {
  background-color: $ds-grey-200;
}

.existing-card-container-selected {
  background-color: $ds-primary-100;
}

.existing-card-icon {
  height: 24px;

  background-color: white;
  border-radius: 2px;
}

.existing-card-ending {
  margin-left: 16px;

  @include p-size-mobile {
    font-size: 12px !important;
  }
}

.existing-card-expire-date {
  margin-left: auto;
  margin-right: 0;

  @include p-size-mobile {
    font-size: 12px !important;
  }
}

.existing-card-change-btn {
  cursor: pointer;

  margin-left: auto;
  margin-right: 0;

  &:hover {
    color: $ds-primary-700;
  }
}

.agreement-container {
  display: flex;
  justify-content: center;
}

.modal-content {
  padding: 0 24px 24px 24px;
}

.default-payment-method-label {
  min-height: auto;
}
