@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.existing-card-dropdown {
  height: 48px;
}

.existing-card-container {
  height: 48px;

  background-color: $ds-white;

  &:hover {
    background-color: $ds-grey-200;
  }

  &-selected {
    background-color: $ds-primary-100;
  }
}

.existing-card-icon {
  height: 24px;
  width: 36px;
  padding: 2px;
}

.existing-card-ending {
  margin-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;

  @include p-size-mobile {
    font-size: 12px !important;
  }
}

.existing-card-expire-date {
  margin-left: auto;
  margin-right: 0;

  @include p-size-mobile {
    font-size: 12px !important;
  }
}

.payment-select-container {
  max-width: 100%;
}
