@import 'mixins';
@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.signin-container {
  @include question-container;
}

.check-email-container {
  @include question-container;
}

.inner-container {
  @include appear-in;
}

.navButton {
  @include p-size-desktop {
    padding: 0;
  }
}

.loginButton {
  @include p-size-mobile {
    width: 100%;
  }
}

.button-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
  @include p-size-mobile {    
    button {
      flex: 1;
      margin-right: 0;
    }
  }
}
