@import '@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/colors';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include p-size-tablet {
    flex-direction: row;
    align-items: flex-start;
  }
}

.inputWrapper {
  position: relative;
  flex: 1;
  
  input {
    padding-right: 48px; 
  }
}

.inputCheckmark {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.input {
  width: 100%;
  
  @include p-size-tablet {
    flex: 1;
  }
}

.button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @include p-size-tablet {
    width: auto;
    min-width: 120px;
    margin-left: 16px;
  }

  &:disabled {
    opacity: 50%;
  }
}
