@import '@popsure/dirty-swan/dist/grid';

.cell {
  display: flex;
  align-items: flex-start;
}

.cellValue {
  word-break: break-word;

  @include p-size-mobile {
    max-width: 120px;
  }
}

.cellValueList {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.noBreak {
  word-break: normal;
  white-space: nowrap;
  max-width: 100% !important;
}