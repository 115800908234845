@import '@popsure/dirty-swan/dist/grid';

.container {
  padding: 24px;

  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(97, 96, 162, 0.01);

  @include p-size-tablet {
    min-width: 360px;
  }
}

.divider {
  height: 1px;
}
